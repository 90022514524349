<template>
  <el-drawer v-model="drawer" :with-header="false">
    <div class="select-container">
      <el-select
        v-model="idx"
        filterable
        remote
        reserve-keyword
        placeholder="请输入地址搜索"
        :remote-method="search"
        :loading="loading"
        @change="chooseAddress"
      >
        <el-option
          v-for="(item, index) in addressList"
          :key="index"
          :label="formatLabel(item)"
          :value="index"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="confirm">确认</el-button>
    </div>
    <el-tag v-if="address" size="large" class="address">{{ address }}</el-tag>
    <div id="container"></div>
  </el-drawer>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import mapIc from '@/assets/image/about/location-map.png'

export default {
  emits: ['confirm'],
  data() {
    return {
      auto: '',
      map: null,
      drawer: false,
      loading: false,
      idx: '',
      addressList: [],
      lng: null,
      lat: null,
      lngAndLat: '',
      address: '',
      autoComplete: null,
      placeSearch: null,
      mapIc,
    }
  },
  mounted() {
    // DOM初始化完成进行地图初始化
    // this.initMap()
  },
  methods: {
    initMap(lng = '', lat = '', address = '') {
      console.log(lng, lat, address)
      this.drawer = true
      let mapOptions = {}
      if (lng && lat) {
        this.address = address
        mapOptions = {
          // 设置地图容器id
          viewMode: '3D', // 是否为3D地图模式
          zoom: 20, // 初始化地图级别
          center: [lng, lat], // 初始化地图中心点位置 不设置默认当前位置
        }
      } else {
        mapOptions = {
          // 设置地图容器id
          viewMode: '3D', // 是否为3D地图模式
          zoom: 20, // 初始化地图级别
        }
      }
      AMapLoader.load({
        key: 'c8390e6c3f2d614263a7c52e3f167af5', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.ToolBar', 'AMap.Scale', 'AMaP.Marker'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          this.map = new AMap.Map('container', mapOptions)
          // 添加工具控制
          this.map.addControl(new AMap.ToolBar())
          this.map.addControl(new AMap.Scale())

          // 注册AutoComplete组件
          this.autoComplete = new AMap.AutoComplete({
            city: '全国',
          })

          // 注册placeSearch组件
          this.placeSearch = new AMap.PlaceSearch({
            // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
            city: '全国',
            map: this.map, // 展现结果的地图实例
            pageSize: 15, // 单页显示结果条数
            pageIndex: 1, // 页码
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          })

          // 添加点标记
          if (lng && lat) this.addMarker('', lng, lat)
        })
        .catch(e => {
          console.log(e)
        })
    },
    // 进行地图搜索 使用autocomplete填充数据
    search(keyWord) {
      this.addressList = []
      this.idx = ''
      this.loading = true
      this.autoComplete.search(keyWord, (status, result) => {
        console.log('autoComplete', status, result)
        // 搜索成功时，result即是对应的匹配数据
        this.addressList = result.tips
        this.loading = false
      })
      // return
      // var autoOptions = {
      //     city: '全国',
      //     map: this.map, // 展现结果的地图实例
      //     pageSize: 15, // 单页显示结果条数
      //     pageIndex: 1, // 页码
      //     autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      // };
      // var placeSearch = new AMap.PlaceSearch(autoOptions);
      // placeSearch.search(keyWord, (status, result) => {
      //     console.log(status, result)
      //     // 搜索成功时，result即是对应的匹配数据
      //     if (status == 'complete') {
      //         if (result.poiList.pois.length > 0) {
      //             this.addressList = result.poiList.pois
      //         } else {
      //             this.$message.warning('没有查询到对应的地址')
      //         }
      //     } else if (status == 'no_data') {
      //         this.$message.warning('没有查询到对应的地址')
      //     }
      // });
    },
    // 选择地址
    chooseAddress(idx) {
      const val = this.addressList[idx]
      const poiId = val.id
      // 选择地址时 查询对应的POI点
      this.placeSearch.getDetails(poiId, (status, result) => {
        console.log('placeSearch', status, result)
        if (status == 'complete') {
          if (result.poiList.pois.length > 0) {
            this.lng = val.location.lng
            this.lat = val.location.lat
            this.lngAndLat = `${this.lng}，${this.lat}`
            this.address = val.district + val.address + val.name
            this.addMarker(result, this.lng, this.lat)
          } else {
            this.$message.warning('没有查询到对应的地址')
          }
        } else if (status == 'no_data') {
          this.$message.warning('没有查询到对应的地址')
        }
      })
    },
    // 添加标记
    addMarker(data, lng, lat) {
      this.map.clearMap()
      console.log('addMarker', lng, lat)
      if (data) {
        const poiArr = data.poiList.pois
        const marker = new AMap.Marker({
          icon: mapIc,
          position: poiArr[0].location,
          map: this.map,
        })
        this.map.setCenter(marker.getPosition())
      } else {
        const marker = new AMap.Marker({
          icon: mapIc,
          position: [lng, lat],
          map: this.map,
        })
        this.map.add(marker)
        this.map.setFitView()
      }
    },
    // 确认选择
    confirm() {
      this.$emit('confirm', {
        lng: this.lng,
        lat: this.lat,
        lngAndLat: this.lngAndLat,
        address: this.address,
      })
      this.drawer = false
    },
    // 整理标签
    formatLabel(item) {
      if (item.name) return `${item.name} - ${item.district}${item.address}`
      return this.address
    },
  },
}
</script>

<style lang="scss" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}
.select-container {
  @include flex-between-align;
  margin-bottom: 10px;
  .el-select {
    flex: 1;
  }
}
.address {
  margin-bottom: 10px;
}
::v-deep(.el-tag--large) {
  padding: 0 11px;
  height: 32px;
  font-size: 14px;
}
</style>
